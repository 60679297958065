<template>
    <AutoComplete
        v-model="search_key"
        placeholder="搜索"
        @on-change = "doSearch"
        @on-blur  = 'blur'
    >
        <Option v-for="option in datas" :value="option.name" :tag="option.name" :key="option.name" @click.native="onClicked(option)">
            <span class="text-main">{{ option.name }}</span>
        </Option>
    </AutoComplete>
</template>

<script>

import { searchCustom } from '@/api/custom/custom';

export default {
    name: "custom-search",
    props: {
        value     : String,    // {id, name, logo}
    },
    components: {
    },
    data () {
        let self = (window.x = this);
        return {
            search_key: '',
            data: '',
            datas: [],
            loading: false,
        }
    },
    methods: {
        doSearch (value) {
            let self = this;
            // console.log("unique_tournament SEARCH:", value,  self.value);
            if (value && (value !== self.data)){
                self.loading = true;
                let params = {
                    search_key: value,
                }
                searchCustom(params).then(response => {
                    if (response.data.code == 0) {
                        self.datas = response.data.data.list;
                    } else {
                        this.$Message.error(response.data.msg);
                    }
                })
                self.loading = false;
            }else if (value && ( value === self.data)){
                self.datas = [{"name": self.data }]
            }
        },
        // end
        onClicked(data){
            // console.log("onClicked(option)", option)
            let self = this;
            self.data = data.name;
            self._emit();
            self.search_key = data.name;
        },
        // end
        _emit(){
            // console.log("in emit", this.data);
            this.$emit('input', this.data);
        },
        // end
        blur(){
            // 失去焦点时触发
            let self = this;
            if (self.search_key){
                self.search_key = self.data.name;
            }else{
                self.data = '';
                self.datas = [];
            }
            self._emit();
        },
        // end
    },
    mounted () {
    },
    watch: {
        'value': {
            handler: function(value){
                this.data = value;
                this.search_key = value;
            },
            deep: true,
        },
        // end 
    },
    computed: {
    }
}
</script>
<style scoped>
    /* @import "search.css" */
</style>