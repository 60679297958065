<template>
  <div>
    <Drawer
        v-model="drawer_edit"
        width="450"
        :mask-closable="true"
    >
        <customLimitEdit :limit_id="edit_limit_id" @closePage="closeEditPage"></customLimitEdit>
    </Drawer>  

    <Row style="margin-bottom:10px;" :gutter="16" align="middle">
        <Col span="4" style="text-align: left">
            <Input v-model="search_key" placeholder="搜索" search @on-search="onSearch" />
        </Col>
        <Col span="4" style="text-align: left">
            <Select v-model="obj_type" @on-change="apiObjTypeChange">
                <Option v-for="item in $conf.custom.obj_types" :value="item.id" :key="item.id" :label="item.name"></Option>
            </Select>
        </Col>
        <Col span="16" style="text-align: right">
            <Button type="success"  @click="edit_limit_id=0 ; drawer_edit=true">新增</Button>
        </Col>
    </Row>
    <br />
    <Table 
        max-height="600" 
        stripe 
        :columns="columns" 
        :data="data" 
        :loading="loading" 
    >
    </Table>
    <Page
        :total="total_page"
        :current="current_page"
        :page-size="page_size"
        @on-change="onPageChange"
        show-total
        show-elevator
    ></Page>
  </div>
</template>
<script>

import { getCustomLimit } from '@/api/custom/custom_limit';
import customLimitEdit from './edit.vue';

const editButton = (vm, h, params) => {
    return h(
        'Button',
        {
            style: {
                margin: '2px 2px'
            },
            props: {
                type: 'info',
            },
            on: {
                click: () => {
                    vm.edit_limit_id = params.row.id;
                    vm.drawer_edit = true;
                }
            }
        },
        '编辑'
    )
}

export default {
    name: "custom-limit-list",
    props: {
        custom_name: String,   // 用户名称
    },
    components: {
        customLimitEdit,
    },
    data () {
        let self = (window.x = this)
        return {
            obj_type: 0,
            drawer_edit: false,
            edit_limit_id: 0,
            search_key: '',
            loading: true,
            total_page: 0,
            page_size: 0,
            current_page: 1,
            columns: [
                {
                    title: 'ID',
                    align: 'center',
                    // width: 200,
                    key: 'id',
                },
                {
                    title: '客户名称',
                    align: 'center',
                    // width: 200,
                    key: 'custom_name',
                },
                {
                    title: 'sport_id',
                    // width: 200,
                    align: 'center',
                    key: 'sport_id',
                },
                {
                    title: 'sport_name',
                    // width: 200,
                    align: 'center',
                    key: 'sport_name',
                    render: function (h, params) {
                        let currentRow = params.row;
                        let sport = self.$conf.sports[currentRow.sport_id]
                        return h( 'span', sport? sport.name : '未知id: '+ String(currentRow.sport_id) )
                    }
                },
                {
                    title: '类型',
                    // width: 200,
                    align: 'center',
                    key: 'obj_type',
                    render: function (h, params) {
                        let currentRow = params.row;
                        let obj_type = self.$conf.custom.obj_types[currentRow.obj_type]
                        return h( 'span', obj_type? obj_type.name : '未知id: '+ String(currentRow.obj_type) )
                    }
                },
                {
                    title: 'obj_id',
                    align: 'center',
                    // width: 200,
                    key: 'obj_id',
                },
                {
                    title: '类型名称',
                    align: 'left',
                    key: 'obj_name',
                    // width: 300,
                },
                {
                    title: '操作',
                    key: 'handlers',
                    align: 'center',
                    // width: 120,
                    render (h, params) {
                        return [ editButton(self, h, params), ]
                    }
                }
            ],
            data: [],
        }
    },
    methods: {
        apiObjTypeChange(){
            this.current_page = 1;
            this.getData();
        },
        onPageChange (page) {
            this.current_page = page
            this.getData()
        },
        onSearch (search_key) {
            this.current_page = 1;
            this.search_key = search_key;
            this.getData();
        },
        // end
        getData () {
            this.loading = true;
            // 搜索日期
            var params = {
                name: this.custom_name,
                search_key: this.search_key,
                page: this.current_page,
                obj_type: this.obj_type,
            }
            getCustomLimit(params).then(response => {
                // console.log(response)
                if (response.data.code == 0) {
                    this.data = response.data.data.list;
                    this.total_page = response.data.data.total_page;
                    this.page_size = response.data.data.page_size;
                    this.current_page = response.data.data.current_page;
                    this.loading = false;
                } else {
                    this.$Message.error(response.data.msg);
                }
            })
        },
        // end
        closeEditPage(){
            let self = this;
            self.drawer_edit = false;
            self.getData();
        },
        // end 
    },
    mounted () {
        this.getData();
    },
    watch: {
    },
    computed: {}
}
</script>
<style scoped>
    /* @import "list.css" */
</style>