<template>
    <div>
        <Spin size="large" fix v-if="spinShow"></Spin>
        <Form label-position="left" :label-width="80" >
            <FormItem label="ID: " v-if="data.id && data.id>0" >
                <Row>
                    <Col span="20" >
                        <Input v-model="data.id" disabled></Input>
                    </Col>
                </Row>
            </FormItem>

            <FormItem label="用户名: ">
                <Row>
                    <Col span="20" >
                        <!-- <Input v-model="data.custom_name" ></Input> -->
                        <customSearch v-model="data.custom_name" ></customSearch>
                    </Col>
                </Row>
            </FormItem>

            <FormItem label="sport_name: ">
                <Row>
                    <Col span="20" >
                        <Select v-model="data.sport_id">
                            <Option v-for="item in $conf.sports" :value="item.id" :key="item.id" :label="item.name"></Option>
                        </Select>
                    </Col>
                </Row>
            </FormItem>

            <FormItem label="类型: ">
                <Row>
                    <Col span="20" >
                        <Select v-model="data.obj_type">
                            <Option v-for="item in $conf.custom.obj_types" :value="item.id" :key="item.id" :label="item.name"></Option>
                        </Select>
                    </Col>
                </Row>
            </FormItem>

            <!-- 编辑单条数据 -->
            <FormItem label="obj_id: " v-if="data.obj_type === 1 && data.id && data.id > 0">
                <Row>
                    <Col span="20" >
                        <Input v-model="data.obj_id" ></Input>
                    </Col>
                </Row>
            </FormItem>

            <FormItem label="语言: " v-if="data.obj_type === 2 && data.id && data.id > 0">
                <Row>
                    <Col span="20" >
                        <Select v-model="data.obj_id">
                            <Option v-for="item in languages" :value="item.id" :key="item.id" :label="item.name_zh"></Option>
                        </Select>
                    </Col>
                </Row>
            </FormItem>
            <!-- end -->

            <!-- 新增多条id -->
            <FormItem label="obj_ids: " v-if="data.obj_type === 1 && (! data.id) ">
                <Row>
                    <Col span="20" >
                        <Input v-model="data.obj_ids" placeholder="多条使用','分隔 "></Input>
                    </Col>
                </Row>
            </FormItem>
            <FormItem label="语言: " v-if="data.obj_type === 2 && (! data.id) ">
                <Row>
                    <Col span="20" >
                        <!-- <Input v-model="data.obj_ids" ></Input> -->
                        <Select v-model="data.obj_ids" multiple>
                            <Option v-for="item in languages" :value="item.id" :key="item.id" :label="item.name_zh"></Option>
                        </Select>
                    </Col>
                </Row>
            </FormItem>
            <!--  -->

            <FormItem label="是否删除: ">
                <Row>
                    <Col span="20" >
                        <Select v-model="data.deleted" >
                            <Option :value="1" key="1">是</Option>
                            <Option :value="0" key="0">否</Option>
                        </Select>
                    </Col>
                </Row>
            </FormItem>
        </Form>
        <Row  align="middle">
            <Button type="primary"  @click="save" long size="default">保存</Button>
        </Row>
    </div>
</template>
<script>

import { getCustomLimit, editCustomLimit} from '@/api/custom/custom_limit';
import { getLanguage} from '@/api/custom/language';
import customSearch from '../custom/search.vue';

export default {
    name: "custom-edit",
    props: {
        limit_id: Number,   // 客户名称
    },
    components: {
        // serviceUrlPicker,
        customSearch,
    },
    data () {
        let self = (window.x = this)
        return {
            spinShow: false,
            modal_password: false,
            new_password: '',
            new_password_confirm: '',
            modal_pickurl: false,
            edit_group: {
                group_id: 0,
                url_infos: []
            },
            all_groups: [],
            data: {
                obj_type: 1,
                deleted: 0,
            },
            languages: {},
        }
    },
    methods: {
        getLanguages(){
            getLanguage().then(response => {
                // console.log(response)
                if (response.data.code == 0) {
                    if (response.data.data){
                        this.languages = response.data.data;
                    }else{
                        this.languages = {}
                    }
                } else {
                    self.$Message.error(response.data.msg)
                }
            })
        },
        // end 
        clearPassword(){
            let self = this;
            self.new_password = '';
            self.new_password_confirm = '';
        },
        // end 
        checkPassword(){
            let self = this;
            if (self.new_password.length >0 || self.new_password_confirm.length > 0){
                if (self.new_password.length < 8){
                    self.$Message.error("密码长度至少为8个字符")
                    // self.modal_password = true;
                    return
                }
                if (self.new_password !== self.new_password_confirm){
                    self.$Message.error("密码不一致")
                    // self.modal_password = true;
                    return
                }
                self.$Message.success("密码设置成功")
                self.data.password = self.new_password;
                self.clearPassword();
            }
        },
        // end 
        getData () {
            let self = this;
            if (self.limit_id && self.limit_id > 0){
                this.spinShow = true;
                // 搜索日期
                var params = {
                    "id" : this.limit_id,
                    "page" : 1, 
                }
                getCustomLimit( params ).then(response => {
                    // console.log(response)
                    if (response.data.code == 0) {
                        if (response.data.data.list && response.data.data.list.length > 0){
                            this.data = response.data.data.list[0];
                        }else{
                            this.data = {}
                        }
                        self.spinShow = false
                    } else {
                        self.$Message.error(response.data.msg)
                    }
                })
            }else{
                self.data = {
                    obj_type: 1,
                    deleted: 0,
                }
            }
            
        },
        // end 
        emit(func_name){
            this.$emit('closePage', func_name)
        },
        // end 
        save() {
            let self = this;
            // console.log(self.data)
            let obj_ids = self.data.obj_ids;
            // console.log(typeof obj_ids, obj_ids)
            if ( typeof obj_ids === 'string' ){
                obj_ids = obj_ids.split(',');
                obj_ids = obj_ids.map(i => parseInt(i, 0) );
            }else if (typeof obj_ids === 'object'){

            }else{
                obj_ids = [];
            }
            // console.log(obj_ids, typeof(obj_ids))
            let params = {
                id: self.data.id,
                custom_name: self.data.custom_name,
                sport_id: self.data.sport_id,
                obj_type: self.data.obj_type,
                obj_id: self.data.obj_id,
                obj_ids: obj_ids, // self.data.obj_ids,
                deleted: self.data.deleted,
            }
            // console.log(params);
            editCustomLimit(params).then(response => {
                if (response.data.code == 0) {
                    this.$Message.success("保存成功")
                    self.emit("save")
                } else {
                    this.$Message.error(response.data.msg)
                }
            })
        },
        // end
    },
    mounted () {
        let self = this;
        self.getLanguages();
    },
    watch: {
        'limit_id': {
            handler(value){
                let self = this;
                self.getData();
            },
            deep: true,
	        immediate: true,  // 深度触发
        },
        // end 
    },
    computed: {
        
    }
}
</script>

<style scoped>
    /* @import "detail.css" */
</style>