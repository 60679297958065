import http from '@/utils/http';

// 获取
export const getCustom = (params) => {
    return http.request({
        url: '/v1/cms_api/custom/custom/list',
        method: 'get',
        params: params
    })
}

// 更新
export const editCustom = (params) => {
    return http.request({
        url: '/v1/cms_api/custom/custom/edit',
        method: 'post',
        data: params
    })
}

// 搜索
export const searchCustom = (params) => {
    return http.request({
        url: '/v1/cms_api/custom/custom/search',
        method: 'post',
        data: params
    })
}