import http from '@/utils/http';

// 获取
export const getLanguage = (params) => {
    return http.request({
        url: '/v1/cms_api/custom/language/get',
        method: 'get',
        params: params
    })
}
